import React from "react";
import {connect} from "react-redux";
import {cloneDeep, get} from "lodash"
import {DevType} from "../../constans/devices";
import {isUsingFakeData} from "../../utils/SettingsUtils";
import {withTranslation} from "react-i18next";

export class NoConnectionTooltipContent extends React.Component {

    render() {
        const {mqttConnected, t, status, devices, mqttReconnecting} = this.props;
        let noConnection = devices.filter(dev => {
            let delay = get(status.get(dev.DevID), "delay");
            return dev.DevType === DevType.GATEWAY && !delay && delay !== null
        });
        let responseTimes = cloneDeep(devices || []).filter(dev => {
            let delay = get(status.get(dev.DevID), "delay");
            return dev.DevType === DevType.GATEWAY && delay !== undefined
        }).map(dev => ({
            Name: dev.Name,
            Delay: get(status.get(dev.DevID), "delay")
        }));
        if (isUsingFakeData()) {
            return (
                <div className={"no-connection-content"}>
                    <b>{t("mqttActive")}</b>
                    {
                        noConnection.map(dev => (
                            <div className="no-break-word">{dev.Name} <b>{Math.round(200 + (Math.random() * 50))}ms</b>
                            </div>
                        ))
                    }
                    {
                        responseTimes.map(dev => (
                            <div className="no-break-word">{dev.Name} <b>{Math.round(200 + (Math.random() * 50))}ms</b>
                            </div>
                        ))
                    }
                </div>
            )
        }
        return (
            <div className={"no-connection-content"}>
                {
                    !mqttConnected &&
                    <b>{t("noMQTT")}</b>
                }
                {
                    mqttConnected &&
                    <b>{t("mqttActive")}</b>
                }
                {
                    mqttReconnecting &&
                    <div><b>{t("mqttReconnecting")}</b></div>
                }
                {
                    noConnection.map(dev => (
                        <div className="no-break-word">{dev.Name} <i
                            className={`fas ${status.get(dev.DevID) ? 'fa-spin fa-circle-notch' : 'fa-times'} fa-fw`}/>
                        </div>
                    ))
                }
                {
                    responseTimes.map(dev => (
                        <div className="no-break-word">{dev.Name} <b>{dev.Delay || "?"}ms</b></div>
                    ))
                }
            </div>
        );
    }

}

NoConnectionTooltipContent = connect(state => ({
    mqttConnected: state.mqtt.connected,
    status: state.mqtt.status,
    devices: state.farmDevices.devices,
    mqttReconnecting: state.mqtt.reconnecting
}))(NoConnectionTooltipContent);

export default withTranslation()(NoConnectionTooltipContent);
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash"

const DefaultFontSize = 15;
export default class Text extends React.Component {


    constructor(props) {
        super(props);
        this.textRef = React.createRef();
        this.state = {
            attributes: this.getAttributes(this.props)
        }
    }

    componentDidMount() {
        this.setState({
            attributes: this.getAttributes(this.props)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevState.attributes, this.getAttributes(this.props))) {
            this.setState({
                attributes: this.getAttributes(this.props)
            })
        }
    }

    getAttributes = (props = this.props) => {
        const {elementRef: {current}} = props;
        if (current) {
            let box = current.getBBox();
            let fontSize = DefaultFontSize * this.getScale();
            fontSize = fontSize > 15 ? 15 : fontSize;
            let addtionalStyles = fontSize ? {} : {fill: 'none'};
            return {
                ...addtionalStyles,
                x: box.x + (box.width / 2),
                y: box.y + (box.height / 2),
                width: box.width,
                height: box.height,
                fontSize: fontSize
            }
        } else {
            return {fontSize: 15, fill: 'none'}
        }
    };

    getScale = () => {
        const {props: {elementRef: {current: container}}, textRef: {current: text}} = this;
        if (container && text) {
            return (0.95 * Math.min((container.getBBox().width / text.getBBox().width), (container.getBBox().height / text.getBBox().height)))
        } else {
            return 0;
        }
    };

    render() {
        const {txt, style, onClick} = this.props;
        return (
            <>
                <text ref={this.textRef} fontSize={DefaultFontSize} opacity={0}>{txt}</text>
                <text onClick={onClick} style={style} {...this.state.attributes}>{txt}</text>
            </>
        )
    }
}

Text.propTypes = {
    elementRef: PropTypes.object.isRequired,
    txt: PropTypes.string.isRequired,
    style: PropTypes.object
};

Text.defaultProps = {
    style: {}
};

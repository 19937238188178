import React from "react";
import "./_farm-map-dialog.scss"
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import animalsDB from "../../database/animalsDB";
import {Level} from "../../constans/levelTypes";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

@connect(state => ({
    animalsTime: state.animals.time
}))
export class FarmMapDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            left: 0,
            top: 0,
            arrowLeft: 0
        };

        this.container = React.createRef();
        this.arrow = React.createRef();
    }

    componentDidMount() {
        if (this.container.current) {
            this.setState({
                top: this.getTopPosition(),
                ...this.getLeftPosition(),
                count: this.getAnimals(this.props)
            })
        }

        window.addEventListener("mousedown", this.clickListener)
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.clickListener)
    }

    clickListener = e => {
        const {container: {current}, props: {element, onOutsideClick}} = this;
        if ((current && !current.contains(e.target)) && (element && !element.contains(e.target))) {
            onOutsideClick();
        }
    };

    getLeftPosition(props = this.props) {
        const {left, width} = props;
        let dialogWidth = this.container.current.clientWidth;
        let container = document.getElementsByClassName("farm-map")[0];
        if (dialogWidth > width) {
            let diff = (dialogWidth - width) / 2;
            let l = left - diff;
            let arrowLeft = 0;
            if (window.innerWidth < l + this.container.current.clientWidth) {
                l = window.innerWidth - this.container.current.clientWidth;
                arrowLeft = (left + width / 2) - l - 10;
            } else {
                let rect = container.getBoundingClientRect();
                if (l < rect.x) {
                    l = rect.x;
                    arrowLeft = 10;
                } else {
                    arrowLeft = (this.container.current.clientWidth / 2) - 10;
                }
            }
            return {
                left: l,
                arrowLeft
            };
        } else {
            let diff = (width - dialogWidth) / 2;
            return {left: left + diff};
        }
    }

    getTopPosition(props = this.props) {
        const {top} = props;
        return top - this.container.current.clientHeight
    }

    getAnimals(props = this.props) {
        const {PlcmntID} = props;
        //tego na te chwile sie nie pozbywam bo tylko na clicku wykonywane
        return animalsDB.getAnimalCount({CID: PlcmntID}, Level.CHAMBER);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.animalsTime !== this.props.animalsTime) {
            this.setState({
                count: this.getAnimals(this.props)
            })
        }
    }

    render() {
        const {t} = this.props;
        const {top, left, arrowLeft, count} = this.state;
        return ReactDOM.createPortal((
            <div className="farm-map-dialog" style={{left, top}} ref={this.container}>
                <div className="farm-map-dialog-animal-list">
                    <div>
                        <h4>{count && count.sowsCount || 0}</h4>
                        <span>{t("farms.farmItem.sows")}</span>
                    </div>
                    <div>
                        <h4>{count && count.boarsCount || 0}</h4>
                        <span>{t("farms.farmItem.boars")}</span>
                    </div>
                    <div>
                        <h4>{count && count.pigletCount || 0}</h4>
                        <span>{t("farms.farmItem.piglets")}</span>
                    </div>
                    <div>
                        <h4>{count && count.porkerCount || 0}</h4>
                        <span>{t("farms.farmItem.porkers")}</span>
                    </div>
                </div>
                <div className="farm-map-dialog-arrow" ref={this.arrow} style={{left: arrowLeft}}/>
            </div>
        ), document.getElementById("root") || document.createElement("div"))
    }

}

FarmMapDialog.propTypes = {
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    PlcmntID: PropTypes.string.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    element: PropTypes.element.isRequired
};

export default withTranslation()(FarmMapDialog);

import React, {Component} from "react";
import {connect} from "react-redux";
import {LinkContainer} from 'react-router-bootstrap';
import {withRouter} from "react-router-dom";
import "./_main-nav-button.scss"
import withRoles from "../withRoles";
import Button from "../basics/button/Button";
import ReactTooltip from 'react-tooltip';

@connect((store) => {
    return {
        navMenuElementShow: store.location.navMenuElementShow,
    };
})
export class MainNavButton extends Component {
    constructor(props) {
        super(props);
        this.textRef = React.createRef();
        this.state = {
            showToolTip: false,
            shouldShow: !!((!!this.props.history && this.props.history.location) && (this.props.href === this.props.history.location.pathname))
        }
    }

    onHover = () => {
        const {showToolTip} = this.state;
        if (!showToolTip && this.isOverflown(this.textRef.current)) {
            this.setState({
                showToolTip: true
            });
        }
    };

    onHoverLeave = () => {
        this.setState({
            showToolTip: false
        });
    };

    isOverflown = ({clientWidth, scrollWidth}) => {
        return scrollWidth > clientWidth;
    };

    handleRedirect = (path) => {
        this.props.history.push(path);
    };

    componentDidMount() {
        const {history, locations} = this.props;
        history.listen(e => {
            let path = [];
            try {
                path = e.pathname.split("/")
            } catch (e) {
                path = [];
            }
            let tmp = !!(locations && locations.filter(loc => path.includes(loc))[0]);
            if (this.state.shouldShow !== tmp) {
                this.setState({shouldShow: (tmp)})
            }
            if (!tmp) {
                this.props.onDeselect && this.props.onDeselect();
            }
        });
    }

    render() {
        const {expanded, href, name, icon, counter} = this.props;
        const {shouldShow, showToolTip} = this.state;
        return (
            <LinkContainer to={href}>
                <div className="main-nav-button-container">
                    <Button className={`main-nav-button ${expanded ? 'expanded' : ''} ${counter ? 'show-badge': ''}`} selected={!!shouldShow} buttonColor={"white"}
                            buttonStyle={"text"}
                            icon={<i data-counter={counter || undefined} className={`fa-fw ${icon}`}/>} type={"button"}>
                        {expanded && <><span style={{width: "80%"}} data-tip={name}
                                             data-counter={counter}
                                             onMouseOut={this.onHoverLeave}
                                             onMouseOver={this.onHover}
                                             ref={this.textRef}>{name}</span></>}
                        {showToolTip &&
                        <ReactTooltip/>
                        }
                    </Button>
                </div>
            </LinkContainer>
        );

    }
}

// function mapStateToProps(state, ownProps) {
//     return {
//         id: ownProps.params.id,
//         filter: ownProps.location.query.filter
//     };
// }
export const _MainNavButton = MainNavButton;
MainNavButton = withRouter(MainNavButton);
MainNavButton = withRoles(props => ({
    roles: props.roles,
    clientPackage: props.packages
}))(MainNavButton);
export default MainNavButton;

import React from "react";
import PropTypes from "prop-types";
import Hammer from "hammerjs";
import svgPanZoom from "svg-pan-zoom";

export default class ZoomPanComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            zoomPan: null,
        }
    }

    componentDidMount() {
        this.initializeSvgPan();
    }

    initializeSvgPan = () => {
        const {svgId, api} = this.props;
        try {
            if (this.state.zoomPan) {
                this.state.zoomPan.destroy();
            }
        } catch (e) {
            console.error(e);
        }
        const eventsHandler = {
            haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
            , init: (options) => {
                let instance = options.instance
                    , initialScale = 1
                    , pannedX = 0
                    , pannedY = 0;
                this.hammer = new Hammer(options.svgElement, {
                    inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                });
                this.hammer.get('pinch').set({enable: true});
                this.hammer.on('doubletap', (ev) => {
                    instance.zoomIn();
                });
                this.hammer.on('panstart panmove', (ev) => {
                    if (ev.type === 'panstart') {
                        pannedX = 0;
                        pannedY = 0;
                    }
                    instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY});
                    pannedX = ev.deltaX;
                    pannedY = ev.deltaY;
                });
                this.hammer.on('pinchstart pinchmove', (ev) => {
                    // On pinch start remember initial zoom
                    if (ev.type === 'pinchstart') {
                        initialScale = instance.getZoom();
                        instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y});
                    }
                    instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y});
                });
                options.svgElement.addEventListener('touchmove', (e) => {
                    e.preventDefault();
                });
            }
            , destroy: () => {
                this.hammer.destroy();
            }
        };

        this.setState({
            zoomPan: svgPanZoom(`#${svgId}`, {
                customEventsHandler: eventsHandler
            })
        }, () => {
            api({zoomPan: this.state.zoomPan})
        });

    };

    render() {
        const {children} = this.props;
        return children ? children : (<rect/>)
    }
}

ZoomPanComponent.propTypes = {
    svgId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    api: PropTypes.func
};

ZoomPanComponent.defaultProps = {
    api: () => {
    }
};
import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import Proptypes from "prop-types"
import {withRouter} from "react-router-dom";
import _ from "lodash";
import {Col} from "react-bootstrap";
import withRoles from "../withRoles";

export class NavMobileItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldShow: NavMobileItem.checkIfIsActive(this.props.history, this.props.locations)
        }
    }

    static checkIfIsActive = (history, locations) => {
        const {location: {pathname}} = history;
        let path;
        try {
            path = pathname.split("/");
        } catch (e) {
            path = [];
        }
        if (path.length && _.isArray(locations)) {
            return !!locations.filter(loc => path.includes(loc)).length
        }
        return false;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const newActive = NavMobileItem.checkIfIsActive(nextProps.history, nextProps.locations);
        if (newActive !== prevState.shouldShow) {
            return {
                shouldShow: newActive
            }
        }
        return null;
    }

    render() {
        const {name, href, counter, icon, handleHide} = this.props;
        const {shouldShow} = this.state;
        let cName = [];
        (counter && cName.push("show-badge"));
        (shouldShow && cName.push("active"));
        cName = cName.join(" ");
        return (
            <LinkContainer to={href} onClick={handleHide}>
                <Col xl={2} lg={3} md={4} sm={6} xs={12} className={cName}>
                    <i className={`fa-fw ${icon}`}/>
                    <a data-counter={counter || undefined}>{name}</a>
                </Col>
            </LinkContainer>
        );
    }

}

NavMobileItem.propTypes = {
    text: Proptypes.string.isRequired,
    href: Proptypes.string.isRequired,
    onClick: Proptypes.func.isRequired
};
NavMobileItem = withRoles(props => ({
    roles: props.roles,
    clientPackage: props.packages
}))(NavMobileItem);
export default withRouter(NavMobileItem)

import React from "react";
import MapItem from "./MapItem";
import {formValueSelector} from "redux-form";
import {connect} from "react-redux";

const FormName = "settings-buildings-map-manage";

export default class ManageDataProvider extends React.Component {

    render() {
        const {requiredProps, selectedID} = this.props;
        let className = requiredProps.className || "";
        className += selectedID === requiredProps.id ? " info" : "";
        return (
            <MapItem {...requiredProps} className={className}/>
        )
    }
}

const selector = formValueSelector(FormName);
const mapStateToProps = state => {
    return {
        selectedID: selector(state, 'selectedID')
    }
};
ManageDataProvider = connect(mapStateToProps)(ManageDataProvider);
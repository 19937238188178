import React, {Component} from 'react';
import NotificationsSystem from 'reapop';
// import myTheme from './theme';
import theme from 'reapop-theme-wybo';

export class CustomNotificationsSystem extends Component {
    render() {
        return (
            <NotificationsSystem theme={theme}/>
        )
    }
}

export default CustomNotificationsSystem;
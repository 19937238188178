import React from "react";
import {getBigIcon, getMessage, getTitle} from "../../utils/NotificationsUtils";
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import {NotificationStatus} from "../../constans/notifications";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ListItem from "../basics/list/list-item/ListItem";
import {FAReactSVG} from "../basics/fa-svg-icon/FAReactSVG";
import {getColorByName} from "../../utils/ColorUtils";

export class NotificationItem extends React.Component {

    getColor() {
        switch (this.props.notification.NData.Status) {
            case NotificationStatus.INFO:
                return "info";
            case NotificationStatus.ALERT:
                return "error";
            case NotificationStatus.WARN:
                return "warning";
            default:
                return "";
        }
    }

    render() {
        const {t, style, notification, index, readTime} = this.props;
        const message = getMessage(notification, t) || "";
        const title = getTitle(notification, t) || "";
        const icon = getBigIcon(notification);
        const date = moment(notification.DtaCrtTime);
        const isSameDay = moment(notification.DtaCrtTime).startOf("day").isSame(moment().startOf("day"));
        const color = this.getColor();
        return (
            <ListItem style={style}
                      color={color ? getColorByName(color) : undefined}
                      className={`log-item pt-2 pb-2 col opacity-${readTime > notification.DtaCrtTime ? "50" : "100"}`}
                      index={index}>
                <Row>
                    <Col
                        title={title}
                        xs={12} sm={12} md={8} lg={10} xl={10}
                        className={"font-weight-bold order-2 order-md-1"}>
                        <FAReactSVG src={icon} className={"pr-1 fa-fw"}/>
                        {title}
                    </Col>
                    <Col
                        title={date.format("DD.MM.YY HH:mm")}
                        xs={12} sm={12} md={4} lg={2} xl={2}
                        className={"text-right opacity-75 order-1 order-md-2"}>
                        {date.format(isSameDay ? "HH:mm" : "DD.MM.YY HH:mm")}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        className={"text-break pt-1 pb-1"}>
                        {message}
                    </Col>
                </Row>
            </ListItem>
        )
    }

}

NotificationItem = connect(state => ({
    user: state.user.user,
    lang: state.language.lang.lang,
    readTime: state.notificationCenter.readTime
}))(NotificationItem);

export default withTranslation()(NotificationItem);

import React from "react";
import "./_no-connection.scss"
import {connect} from "react-redux";
import Tooltip from "../basics/tooltip/Tooltip";
import {get, isEqual} from "lodash"
import {isMobile} from "../../utils/MobileUtils";
import {pingGateways} from "../../actions/mqttActions";
import {DevType} from "../../constans/devices";
import NoConnectionTooltipContent from "./NoConnectionTooltipContent";
import {isUsingFakeData} from "../../utils/SettingsUtils";
import PropTypes from "prop-types"

export class NoConnection extends React.Component {

    componentDidMount() {
        if (this.props.subscriptions.length > 1) {
            this.props.dispatch(pingGateways());
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {dispatch} = nextProps;
        if (!isEqual(this.props.subscriptions, nextProps.subscriptions) && nextProps.subscriptions.length > 0) {
            dispatch(pingGateways());
        }
    }

    checkIfLoading() {
        const {status, mqttConnecting} = this.props;
        if (mqttConnecting) return true;
        return !![...status.values()].filter(s => s.send).length;
    }

    getType() {
        const {mqttConnected, devices = [], status} = this.props;
        if (isUsingFakeData()) return "success";
        let noConnection = devices.filter(dev => {
            let delay = get(status.get(dev.DevID), "delay");
            return dev.DevType === DevType.GATEWAY && !delay && delay !== null;
        });
        if (this.checkIfLoading()) return "warning";
        if (mqttConnected && noConnection.length === 0) return "success";
        return "warning"
    }

    render() {
        let loading = isUsingFakeData() ? false : this.checkIfLoading();
        const {className} = this.props;
        const cName = ["no-connection", this.getType(), className].filter(o => !!o).join(" ");
        return (
            <Tooltip tooltipContent={<NoConnectionTooltipContent/>} placement={isMobile() ? "top" : "bottom"}
                     type={this.getType()}>
                <div className={cName}>
                    {
                        !loading &&
                        <i className="fas fa-cloud"/>
                    }
                    {
                        loading &&
                        <i className="fas fa-circle-notch fa-spin"/>
                    }
                </div>
            </Tooltip>
        );
    }

}

NoConnection.propTypes = {
    className: PropTypes.string
};

NoConnection = connect(state => ({
    mqttConnecting: state.mqtt.connecting,
    mqttConnected: state.mqtt.connected,
    subscriptions: state.mqtt.subscriptions,
    status: state.mqtt.status,
    devices: state.farmDevices.devices,
    user: state.user.user
}))(NoConnection);

export default NoConnection;
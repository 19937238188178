import React, {Component} from 'react';
import {connect} from 'react-redux';
import {makeGetDeviceByID} from "../../../selectors/deviceSelector";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import MapItem from "../MapItem";
import {get, isNil} from "lodash";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {getScaleUnit} from "../../../utils/SettingsUtils";
import {getColorByName, textContrast} from "../../../utils/ColorUtils";
import {getLatestMetadata} from "../../../utils/DevicesUtils";
import {IntervalTimes} from "../../../constans/intervals";


function makeMapStateToProps() {
    const getDeviceByID = makeGetDeviceByID();
    const getShadowByDevice = makeGetShadowByDevice();
    return function mapStateToProps(state, props) {
        return {
            device: getDeviceByID(state, props),
            shadow: getShadowByDevice(state, props)
        };
    }
}


class ScaleMapItem extends Component {

    hasAlarm = (shadow, index) => {
        const minWeight = get(shadow, `configuration.minWeights[${index}]`, 0);
        const weight = get(shadow, `weights[${index}].value`);
        return minWeight ? weight <= minWeight : false;
    }

    getValues = () => {
        const values = {
            color: getColorByName("warning")
        };
        const {index, shadow: {shadow, fetching, fetched}} = this.props;
        if (shadow) {
            const metadata = getLatestMetadata(get(shadow, "metadata")) || 0;
            const outdated = (+new Date() - metadata) > IntervalTimes.DEVICE_OUTDATED_DATA;
            const weight = get(shadow, `weights[${index}].value`);
            if (isFinite(weight)) {
                const unit = getScaleUnit();
                values.text = convertWeightUnitTo(weight, {
                    showUnit: true,
                    fixed: unit,
                    unit: unit
                })
                if (!outdated) {
                    values.color = weight <= 1000 || this.hasAlarm(shadow) ? getColorByName("error") : getColorByName("primary")
                }
            }
        }
        if (isNil(values.text)) {
            values.text = fetching ? "..." : fetched ? "-" : "!";
        }
        return values;
    }

    render() {
        const {requiredProps, device} = this.props;
        if (!device) {
            return (
                <MapItem {...requiredProps}/>
            )
        }
        const values = this.getValues();
        return (
            <MapItem {...requiredProps} label={values.text} style={{fill: values.color}}
                     labelProps={{style: {fill: textContrast(values.color)}}}
            />

        );
    }
}

export default connect(
    makeMapStateToProps,
)(ScaleMapItem);

import React from "react";
import MapItem from "./MapItem";
import {DevType} from "../../constans/devices";
import ScaleMapItem from "./map-items/ScaleMapItem";
import ClimateMapItem from "./map-items/ClimateMapItem";

export default class DeviceDataProvider extends React.Component {
    render() {
        const {requiredProps, requiredProps: {id, type}} = this.props;
        const params = {};
        console.log("deviceDataProvider->", this.props);
        if (type === "devices") {
            const splited = id.split("_");
            console.log("splitted")
            if (splited.length === 2) {
                params.DevID = splited[0];
                params.index = +splited[1];
            }
            return <ScaleMapItem requiredProps={requiredProps} DevID={params.DevID} index={params.index}/>
        }
        if (type === "chambers") {
            params.PlcmntID = id;
            params.DevType = [DevType.CLIMATE];
            return <ClimateMapItem requiredProps={requiredProps} PlcmntID={params.PlcmntID} DevType={params.DevType}/>
        }
        return (
            <MapItem {...requiredProps}/>
        )

    }

}

import React from "react";
import MapItem from "./MapItem";
import {connect} from "react-redux";
import {get} from "lodash";
import {makeGetPlacementByID} from "../../selectors/buildingsSelector";

export default class DefaultDataProvider extends React.Component {

    getName = () => {
        const {requiredProps: {type}, placement, showOnly} = this.props;
        const showType = showOnly || "chambers";
        if (type === showType) {
            return placement ? placement.name.slice(0).pop() : "?";
        }
        return "";
    };

    getClassName = () => {
        const {requiredProps: {className}, placement} = this.props;
        return `${className} sector-type-${get(placement, "SType", "")}`;
    };

    render() {
        const {requiredProps, placement} = this.props;
        const name = this.getName();
        return (
            <MapItem {...requiredProps} extraArguments={{placement}} svgElementRef={ref => this.itemRef = ref} className={this.getClassName()}
                     label={name}/>
        )
    }

}


function makeMapStateToProps(initialState, initialProps) {
    const getPlacementByID = makeGetPlacementByID();
    const {requiredProps: {id, type}} = initialProps;
    const params = {PlcmntID: id};
    return function mapStateToProps(state, props) {
        return {
            placement: ["chambers", "sectors", "buildings"].includes(type) ? getPlacementByID(state, params) : null
        };
    }
}

DefaultDataProvider = connect(
    makeMapStateToProps
)(DefaultDataProvider);

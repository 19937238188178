import React, {Component} from 'react';
import Card from "../basics/card/Card";
import LoadingComponent from "../loading/LoadingComponent";
import InfoBox from "../basics/info-box/InfoBox";
import PropTypes from "prop-types"

export default class NoDataFoundLoading extends Component {
    render() {
        const {isLoading, dataLength = 0, noDataText = ""} = this.props;
        if (isLoading && !dataLength) {
            return (
                <Card className={"mh-15rem"}>
                    {
                        <LoadingComponent isLoading={isLoading}/>
                    }
                </Card>
            );
        }
        return (
            <InfoBox boxColor={"warning"}>{noDataText}</InfoBox>

        );
    }
}

NoDataFoundLoading.propTypes = {
    dataLength: PropTypes.number.isRequired,
    noDataText: PropTypes.string,
    isLoading: PropTypes.bool.isRequired
};
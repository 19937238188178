import React from "react";
import PropTypes from "prop-types"
import {setValve} from "../../../IOT/device-functions/SeparationCageFunctions";
import {withTranslation} from "react-i18next";

export const status = {
    CLOSED: 0,
    MIDDLE: 1,
    OPENED: 2
};

const doorTypes = {
    ENTRANCE_LEFT: 1.1,
    ENTRANCE_RIGHT: 1.2,
    MIDDLE_LEFT: 2.1,
    MIDDLE_RIGHT: 2.2,
    LEFT: 3,
    RIGHT: 4
};

export class Cage extends React.Component {

    onClickDoor = (valve) => {
        if (this.props.workType === 0) {
            let valveStatus = this.props.valves[valve - 1];
            let st = valveStatus.value === 1 ? 0 : 1;
            setValve(this.props.cage, valve, st);
        }
    };

    getClassName = (door, stat, isError) => {
        let className = "cage-door";
        if (isError) className += " error";
        if (this.props.workType === 0) className += " manual";
        switch (door) {
            case doorTypes.ENTRANCE_LEFT:
                className += " entrance-left";
                break;
            case doorTypes.ENTRANCE_RIGHT:
                className += " entrance-right";
                break;
            case doorTypes.MIDDLE_LEFT:
                className += " middle-left";
                break;
            case doorTypes.MIDDLE_RIGHT:
                className += " middle-right";
                break;
            case doorTypes.LEFT:
                className += " upper-left";
                break;
            case doorTypes.RIGHT:
                className += " upper-right";
                break;
            default:
                className += "";
        }
        switch (stat) {
            case status.OPENED:
                className += " open";
                break;
            case status.MIDDLE:
                className += " middle";
                break;
            default:
                className += "";
        }
        return className;
    };

    getGroupClassName = () => {
        let className = "group";
        if (this.props.workType === 0) className += " manual";
        return className;
    };

    render() {
        const {t} = this.props;
        return (
            <div className="cage-view">
                <svg
                    viewBox="-30 0 220 420"
                >
                    {/*Lewy dół*/}
                    <g>
                        <rect
                            className="cage-wall"
                            ry="4.0815053"
                            y="144.36679"
                            x="17.11323"
                            height="254.72357"
                            width="16.531525"
                        />
                        <rect
                            className="cage-wall"
                            transform="matrix(0.84535183,-0.53420997,0.63943038,0.768849,0,0)"
                            ry="3.7120354"
                            y="109.71523"
                            x="-84.410942"
                            height="35.440826"
                            width="16.114782"
                        />
                    </g>
                    {/*Prawy dół*/}
                    <g>
                        <rect
                            className="cage-wall"
                            width="16.114782"
                            height="35.440826"
                            x="-208.48756"
                            y="23.5044"
                            ry="3.7120354"
                            transform="matrix(-0.84535183,-0.53420997,-0.63943038,0.768849,0,0)"
                        />
                        <rect
                            className="cage-wall"
                            width="16.531525"
                            height="254.72357"
                            x="126.22935"
                            y="144.36679"
                            ry="4.0815053"
                        />
                    </g>
                    {/*Drzwi dół*/}
                    <g
                        onClick={() => this.onClickDoor(1)}
                        className={this.getGroupClassName()}
                    >
                        {/*Drzwi dół lewo*/}
                        <g>
                            <rect
                                width="8.4255161"
                                height="16.216231"
                                x="32.895828"
                                y="367.17856"
                                ry="2.7494175"
                                rx="0.15276258"
                                className="cage-door"
                            />
                            <rect
                                style={{transformOrigin: "39.009052px 373.53601px"}}
                                width="40"
                                height="9"
                                x="39.009052"
                                y="370.53601"
                                ry="2.8388526"
                                className={this.getClassName(doorTypes.ENTRANCE_LEFT, this.props.entrance, this.props.errors[0] || this.props.errors[1])}
                            />
                        </g>
                        {/*Drzwi dół prawo*/}
                        <g>
                            <rect
                                rx="0.15276258"
                                ry="2.7494175"
                                y="367.17856"
                                x="118.9913"
                                height="16.216231"
                                width="8.4255161"
                                className="cage-door"
                            />
                            <rect
                                ry="2.8388526"
                                y="370.53601"
                                x="80.87807"
                                height="9"
                                width="40"
                                style={{transformOrigin: "120.87807px 373.53601px"}}
                                className={this.getClassName(doorTypes.ENTRANCE_RIGHT, this.props.entrance, this.props.errors[0] || this.props.errors[1])}
                            />
                        </g>
                    </g>
                    {/*Drzwi góra*/}
                    <g
                        onClick={() => this.onClickDoor(2)}
                        className={this.getGroupClassName()}
                    >
                        {/*Drzwi góra lewo*/}
                        <g>
                            <rect
                                rx="0.15276258"
                                ry="2.7494175"
                                y="169.17856"
                                x="32.895828"
                                height="16.216231"
                                width="8.4255161"
                                id="rect1040"
                                className="cage-door"
                            />
                            <rect
                                ry="2.8388526"
                                y="172.53601"
                                x="39.009052"
                                height="9"
                                width="40"
                                style={{transformOrigin: "39.009052px 172.53601px"}}
                                className={this.getClassName(doorTypes.MIDDLE_LEFT, this.props.middle, this.props.errors[2] || this.props.errors[3])}
                            />
                        </g>
                        {/*Drzwi góra prawo*/}
                        <g>
                            <rect
                                className="cage-door"
                                width="8.4255161"
                                height="16.216231"
                                x="118.9913"
                                y="169.17856"
                                ry="2.7494175"
                                rx="0.15276258"/>
                            <rect
                                style={{transformOrigin: "120.87807px 172.53601px"}}
                                className={this.getClassName(doorTypes.MIDDLE_RIGHT, this.props.middle, this.props.errors[2] || this.props.errors[3])}
                                width="40"
                                height="9"
                                x="80.87807"
                                y="172.53601"
                                ry="2.8388526"/>
                        </g>
                    </g>
                    {/*Lewa góra*/}
                    <g>
                        <rect
                            ry="3.2688611"
                            rx="0"
                            y="-0.33135179"
                            x="16.820547"
                            height="30.639278"
                            width="16.701103"
                            className="cage-wall"/>
                        <rect
                            transform="matrix(0.70103824,-0.71312368,0.69308774,0.72085323,0,0)"
                            ry="3.465991"
                            y="10.876099"
                            x="-9.4128189"
                            height="36.091709"
                            width="12.891397"
                            className="cage-wall"/>
                    </g>
                    {/*Prawa góra*/}
                    <g>
                        <rect
                            width="12.891397"
                            height="36.091709"
                            x="-124.93063"
                            y="-102.65984"
                            ry="3.465991"
                            transform="matrix(-0.70103824,-0.71312368,-0.69308774,0.72085323,0,0)"
                            className="cage-wall"/>
                        <rect
                            width="16.701103"
                            height="30.639278"
                            x="126.10627"
                            y="0.20436205"
                            rx="0"
                            ry="3.2688611"
                            className="cage-wall"/>
                    </g>
                    {/*Drzwi lewe*/}
                    <rect
                        style={{transformOrigin: "29.798717px 41.172981px"}}
                        className={this.getClassName(doorTypes.LEFT, this.props.left, this.props.errors[4] || this.props.errors[5])}
                        width="9"
                        height="91.864532"
                        x="26.798717"
                        y="41.172981"
                        ry="2.8388526"
                        onClick={() => this.onClickDoor(3)}
                    />
                    {/*Drzwi prawe*/}
                    <rect
                        ry="2.8388526"
                        y="41.172981"
                        x="126.79872"
                        height="91.864532"
                        width="9"
                        style={{transformOrigin: "129.79872px 41.172981px"}}
                        className={this.getClassName(doorTypes.RIGHT, this.props.right, this.props.errors[6] || this.props.errors[7])}
                        onClick={() => this.onClickDoor(4)}
                    />
                    <foreignObject x={54} y={385} width={50} height={30}>
                        <div className="cage-view-passes">{this.props.passes}</div>
                    </foreignObject>
                    <foreignObject x={-30} y={65} width={50} height={30}>
                        <div className="cage-view-passes">{this.props.passesLeft}</div>
                    </foreignObject>
                    <foreignObject x={54} y={0} width={50} height={30}>
                        <div className="cage-view-passes">{this.props.passesMiddle}</div>
                    </foreignObject>
                    <foreignObject x={140} y={65} width={50} height={30}>
                        <div className="cage-view-passes">{this.props.passesRight}</div>
                    </foreignObject>
                </svg>
                <div className="cage-view-weight">
                    <span>
                        <div>
                            {t("chamber.separationCageInfo.current")}
                        </div>
                        {this.props.weight}
                    </span>
                    <span>
                        <div>
                            {t("chamber.separationCageInfo.last")}
                        </div>
                        {this.props.weightClosed}
                    </span>
                </div>
            </div>
        );
    }

}

Cage.propTypes = {
    entrance: PropTypes.oneOf([status.CLOSED, status.MIDDLE, status.OPENED]).isRequired,
    middle: PropTypes.oneOf([status.CLOSED, status.MIDDLE, status.OPENED]).isRequired,
    left: PropTypes.oneOf([status.CLOSED, status.MIDDLE, status.OPENED]).isRequired,
    right: PropTypes.oneOf([status.CLOSED, status.MIDDLE, status.OPENED]).isRequired,
    weight: PropTypes.node.isRequired,
    weightClosed: PropTypes.node.isRequired,
    passes: PropTypes.node.isRequired,
    passesLeft: PropTypes.node.isRequired,
    passesMiddle: PropTypes.node.isRequired,
    passesRight: PropTypes.node.isRequired,
    workType: PropTypes.number.isRequired,
    errors: PropTypes.array.isRequired,
    cage: PropTypes.object,
    valves: PropTypes.array
};

export default withTranslation()(Cage);
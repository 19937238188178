import React from "react";
import PropTypes from "prop-types"
import _ from "lodash";
import "./_farm-map.scss";
import DefaultDataProvider from "./DefaultDataProvider";
import ZoomPanComponent from "./ZoomPanComponent";

export default class FarmMap extends React.Component {

    constructor(props) {
        super(props);
        const {farmMap} = this.props;
        this.svg = React.createRef();
        this.timer = undefined;
        this.state = {
            ...this.buildSVG(farmMap),
            height: 500
        }

    }

    attrToObject = (attr) => {
        return attr ? {[attr.name]: attr.value} : null;
    };

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                height: this.getHeight()
            })
        }, 100)
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const {farmMap} = this.props;
        if (!_.isEqual(farmMap, nextProps.farmMap)) {
            this.setState({
                ...this.buildSVG(nextProps.farmMap)
            })
        }
    }

    onClick = (params) => {
        const {onElementClick} = this.props;
        onElementClick(params);
    };

    getHeight = () => {
        try {
            return window.innerHeight - (this.svg.current.getClientRects()[0].y + 15);
        } catch (e) {
            console.log(e);
            return 500;
        }
    };

    buildSVG = (svgElement) => {
        const {dataProvider, showOnly} = this.props;
        try {
            let levels = [];
            let viewBox = svgElement.attributes.viewBox.value;
            //svgElement.children.buildings.children[1].attributes.item(0).name
            let keys = ["buildings", "sectors", "chambers", "devices"];
            for (let key of keys) {
                let locations = _.get(svgElement, `children.${key}.children`, []);
                for (let i = 0; i < locations.length; i++) {
                    let location = locations[i];
                    let atrs = {};
                    for (let j = 0; j < _.get(location, "attributes.length", 0); j++) {
                        atrs = {
                            ...atrs,
                            ...this.attrToObject(location.attributes.item(j))
                        }
                    }
                    delete atrs.style;
                    let item = React.createElement(dataProvider, {
                        key: `${key}_${i}`,
                        showOnly,
                        requiredProps: {
                            ...atrs,
                            svgElementType: location.nodeName,
                            type: key,
                            id: atrs.id,
                            className: `${key}-item`,
                            onClick: (extraArguments = {}) => {
                                this.onClick({
                                    type: key,
                                    id: atrs.id,
                                    element: item,
                                    ...extraArguments
                                })
                            }

                        }
                    });
                    levels.push(item);
                }
            }
            return {levels, viewBox: viewBox};
        } catch (e) {
            console.error(e);
            return {levels: [], viewBox: "0 0 512 512"};
        }

    };

    render() {
        const {levels, viewBox, height} = this.state;
        const {showOnly, children, calculateHeight} = this.props;
        let cName = "fetura-farm-map-svg";
        cName += showOnly ? ` show-only ${showOnly}` : "";
        return (
            <div className={"farm-map"}>
                <svg ref={this.svg} className={cName} style={calculateHeight ? {height: height} : null}
                     viewBox={viewBox} id={"svg-map"}>
                    <ZoomPanComponent svgId={"svg-map"} api={({zoomPan}) => this.setState(zoomPan)}>
                        {levels}
                    </ZoomPanComponent>
                </svg>
                <div className={"farm-map-extensions"}>
                    {children}
                </div>
            </div>
        );
    }

}

FarmMap.propTypes = {
    farmMap: PropTypes.object.isRequired,
    onElementClick: PropTypes.func.isRequired,
    dataProvider: PropTypes.object,
    showOnly: PropTypes.oneOf(["buildings", "sectors", "chambers", "devices"]),
    calculateHeight: PropTypes.bool,
};

FarmMap.defaultProps = {
    onElementClick: () => {
    },
    dataProvider: DefaultDataProvider,
    calculateHeight: true
};

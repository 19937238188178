import React, {Component} from "react";
import MainNavButton from "./MainNavButton"

export class MainNavLeftBar extends Component {


    render() {
        const {menuItems, expanded} = this.props;
        return (
            <React.Fragment>
                {
                    menuItems.map((menuItem, index) => {
                        if (!menuItem.hide) {
                            return (
                                <MainNavButton expanded={expanded} {...menuItem} key={index}/>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </React.Fragment>
        );

    }
}

export default MainNavLeftBar;
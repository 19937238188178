import React, {Component} from 'react';
import {connect} from 'react-redux';
import {makeGetDevicesInPlacement} from "../../../selectors/deviceSelector";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import MapItem from "../MapItem";
import {get, isNil} from "lodash";
import {convertTemperatureUnitTo} from "../../../utils/UnitUtils";
import {getLatestMetadata} from "../../../utils/DevicesUtils";
import {IntervalTimes} from "../../../constans/intervals";
import {UnitTypes} from "../../../constans/unitTypes";
import {getColorByName, textContrast} from "../../../utils/ColorUtils";


function makeMapStateToProps() {
    const getDevicesInPlacement = makeGetDevicesInPlacement();
    const getShadowByDevice = makeGetShadowByDevice();
    const params = {};
    return function mapStateToProps(state, props) {
        const device = getDevicesInPlacement(state, props)[0] || null;
        if (device) {
            params.DevID = device.DevID;
        } else {
            delete params.DevID;
        }
        return {
            device: device,
            shadow: getShadowByDevice(state, params)
        };
    }
}

class ClimateMapItem extends Component {

    hasAlarm = (shadow) => {
        const temperature = get(shadow, `ambientConditions.averageCurrentAirTemperature`, 0);
        const requestedTemperature = get(shadow, "configuration.requestedAirTemperature", 0);
        const minimumTemperatureAlarm = requestedTemperature - get(shadow, "configuration.alarms.minimumTemperatureAlarmDeviation", 0);
        const maximumTemperatureAlarm = requestedTemperature + get(shadow, "configuration.alarms.maximumTemperatureAlarmDeviation", 0);
        const absoluteAlarm = get(shadow, "configuration.alarms.absoluteAlarm", 0)
        if (temperature > absoluteAlarm) {
            return true;
        }
        if (temperature <= minimumTemperatureAlarm) {
            return true;
        }
        return temperature >= maximumTemperatureAlarm;

    }

    getValues = () => {
        const values = {
            color: getColorByName("warning")
        };
        const {shadow: {shadow, fetching, fetched}} = this.props;
        if (shadow) {
            const metadata = getLatestMetadata(get(shadow, "metadata")) || 0;
            const outdated = (+new Date() - metadata) > IntervalTimes.DEVICE_OUTDATED_DATA;
            const temperature = get(shadow, `ambientConditions.averageCurrentAirTemperature`, 0);
            if (isFinite(temperature)) {
                values.text = convertTemperatureUnitTo(temperature, {
                    unit: UnitTypes.SMALL,
                    fixed: 1,
                    showUnit: true
                });
                if (!outdated) {
                    values.color = this.hasAlarm(shadow) ? getColorByName("error") : getColorByName("primary")
                }
            }
        }
        if (isNil(values.text)) {
            values.text = fetching ? "..." : fetched ? "-" : "!";
        }
        return values;
    }

    render() {
        const {requiredProps, device} = this.props;
        if (!device) return null;
        const values = this.getValues();
        return (
            <MapItem {...requiredProps} label={values.text} style={{fill: values.color}}
                     labelProps={{style: {fill: textContrast(values.color)}}}
            />

        );
    }
}

export default connect(
    makeMapStateToProps,
)(ClimateMapItem);

import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import {omit, isFunction} from "lodash"

export default class MapItem extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    onClick = (e) => {
        e.stopPropagation();
        const {extraArguments={}, onClick} = this.props;
        if(isFunction(onClick)){
            onClick(extraArguments)
        }
        return false;
    }


    render() {
        const {svgElementType, label, labelProps} = this.props;
        let props = omit(this.props, ["svgElementRef", "labelProps", "svgElementType", "onClick"]);
        return (
            <g className={"clickable"} onClick={this.onClick}>
                {
                    React.createElement(svgElementType, {
                        ...props,
                        ref: this.ref,
                    }, null)
                }
                {
                    label && <Text txt={label} onClick={this.onClick} elementRef={this.ref} {...labelProps}/>
                }
            </g>
        )
    }
}

MapItem.propTypes = {
    svgElementType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    svgElementRef: PropTypes.func,
    label: PropTypes.string,
    labelProps: PropTypes.object
};

MapItem.defaultProps = {
    labelProps: {},
    extraArguments: {}
};
